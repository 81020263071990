import React from "react"
import Layout from "../../components/layout/Layout"
import PhotoGallery from "../../components/photogallery/PhotoGallery"
import { getImageNodes } from "../../lib"
import { graphql } from "gatsby"

export default function Wedding({ data }) {
  const IMAGES = getImageNodes(data)
  return (
    <Layout title="Wedding" heading="Wedding">
      <article className="description">
        
      </article>
      <PhotoGallery photos={IMAGES} />
    </Layout>
  )
}

export const query = graphql`
  query Wedding {
    allFile(filter: { relativeDirectory: { eq: "wedding" } }) {
      edges {
        node {
          name
          base
          publicURL
        }
      }
    }
  }
`
